import {ATUFunctionPackage} from "a2u-renderer-common/src/utils/processor/ATUFunctionPackage";
import {ATUFunctionResult} from "a2u-renderer-common/src/utils/processor/ATUFunctionResult";
import moment from 'moment'

export class Horoscope extends ATUFunctionPackage {
    constructor(...props) {
        super(...props);
    }

    /**
     * Configure alarms
     */
    async getUserSign({ birthdayDay, birthdayMonth, birthdayYear }) {

        // Calc users age using date of birth
        const userAge = moment().diff(moment({year: birthdayYear, month: birthdayMonth - 1, day: birthdayDay}), 'years')

        // Calc zodiac sign
        let zodiacSign = "aries"

        // Zodiac table where each sign has start and end day of year (month * 100 + day)
        const zodiacTable = [
            {name: "aries", start: 321, end: 419},
            {name: "taurus", start: 420, end: 520},
            {name: "gemini", start: 521, end: 620},
            {name: "cancer", start: 621, end: 722},
            {name: "leo", start: 723, end: 822},
            {name: "virgo", start: 823, end: 922},
            {name: "libra", start: 923, end: 1022},
            {name: "scorpio", start: 1023, end: 1121},
            {name: "sagittarius", start: 1122, end: 1221},
            {name: "capricorn", start: 1222, end: 119},
            {name: "aquarius", start: 120, end: 218},
            {name: "pisces", start: 219, end: 320}
        ]

        // Calc users birth day to be compatible with zodiac table
        const userBDay = parseInt(birthdayMonth) * 100 + parseInt(birthdayDay)

        // Find zodiac sign
        for(const zodiac of zodiacTable) {
            if(
                (userBDay >= zodiac.start && userBDay <= zodiac.end) ||
                (zodiac.start > zodiac.end && (userBDay >= zodiac.start || userBDay <= zodiac.end))
            ) {
                zodiacSign = zodiac.name
                break
            }
        }

        // Return config
        return new ATUFunctionResult({references: {
                resultSignId: zodiacSign,
                resultUserAge: userAge
            }});
    }
}
